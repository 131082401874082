import { HttpClientModule } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { NoteService } from './notes.services';
import { ActivatedRoute } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedService } from 'src/app/utility/services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrl: './notes.component.scss',
  providers: [NoteService]
})
export class NotesComponent implements OnInit {
  noteList: any[] = [];
  currentLang = 'en';
  activeTabIndex = 0;
  pageType: any;
  constructor(private noteService: NoteService, private route: ActivatedRoute,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,

  ) {
    this.sharedService.getLanguageChange().subscribe(res => {
      this.currentLang = res || 'en';
    })
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const paramValue = params['status'];
      this.getSystemSettingBytype(paramValue);
    });

    this.route.queryParams.subscribe(param => {
      if (param['status'] === 'legal') {
        this.pageType = 'Legal'
      } else {
        this.pageType = 'Privacy';
      }
    });

  }

  getSystemSettingBytype(type: string) {
    this.noteService.getSystemSettingBytype(type).subscribe(res => {
      if (res?.status == 200) {
        this.noteList = res.data;
      } else
        this.noteList = [];
    });
  }
  byPassHTML(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  onTabChange(event: any) {
    const selectedTabIndex = event.index;
    this.activeTabIndex = selectedTabIndex;
    const note = this.noteList[selectedTabIndex];
    this.noteService.getNotesData(note.code).subscribe(res => {
      if (res?.status == 200) {
        note.note_desc = res.data;
      } else
        note.note_desc = [];
    });
  }


}
