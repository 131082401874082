import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    private languageChange: BehaviorSubject<string> = new BehaviorSubject<string>('');

    // To get & set language change
    getLanguageChange(): Observable<string> {
        return this.languageChange.asObservable();
    }

    setLanguageChange(val: string): void {
        this.languageChange.next(val);
    }


}