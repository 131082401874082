<nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied"
    [ngClass]="{'sticky': isSticky}"  >
    <!-- <div class="container"> -->
    <div class="container">
        <a class="navbar-brand" routerLink="/" [class.active]="activeLink === 'home'">
            <img alt="company logo" [src]="logoPath" (click)="onClick('home')" width="200" height="37">
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()" aria-label="menu">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <!-- <li class="nav-item ">
                    <span class="nav-link" [class.active]="activeLink === 'home'"
                        (click)="onClick('home')">{{ 'NAV_BAR.nav-item-link-1-ul-li-a' | translate }}
                    </span>
                </li> -->
                <li class="nav-item">
                    <span class="nav-link" [class.active]="activeLink === 'about'" (click)="onClick('about')">{{
                        'NAV_BAR.nav-item-link-2-ul-li-a' | translate }}
                    </span>
                </li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="onClick('team')">Team</span></li> -->
                <li class="nav-item">
                    <span class="nav-link" [class.active]="activeLink === 'services'" (click)="onClick('services')">{{
                        'NAV_BAR.nav-item-link-3-ul-li-a' | translate }}
                    </span>
                </li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="onClick('work')">Work</span></li> -->
                <li class="nav-item">
                    <span class="nav-link" [class.active]="activeLink === 'pricing'" (click)="onClick('pricing')">{{
                        'NAV_BAR.nav-item-link-5-ul-li-a' | translate }}
                    </span>
                </li>
                <li class="nav-item">
                    <span class="nav-link" [class.active]="activeLink === 'blog'" (click)="onClick('blog')">{{
                        'NAV_BAR.nav-item-link-6-ul-li-a' | translate }}
                    </span>
                </li>
                <li class="nav-item">
                    <span class="nav-link" [class.active]="activeLink === 'contact'" (click)="onClick('contact')">{{
                        'NAV_BAR.nav-item-link-7-ul-li-a' | translate }}
                    </span>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" [class.active]="activeLink === 'home'"
                        (click)="toggleClass(); onClick('home')">{{
                        'NAV_BAR.nav-item-link-1-ul-li-a' | translate }}</span></li>
                <li class="nav-item"><span class="nav-link" [class.active]="activeLink === 'about'"
                        (click)="toggleClass(); onClick('about')">{{
                        'NAV_BAR.nav-item-link-2-ul-li-a' | translate }}</span></li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('team')">Team</span></li> -->
                <li class="nav-item"><span class="nav-link" [class.active]="activeLink === 'services'"
                        (click)="toggleClass(); onClick('services')">{{
                        'NAV_BAR.nav-item-link-3-ul-li-a' | translate }}</span></li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('work')">Work</span></li> -->
                <li class="nav-item"><span class="nav-link" [class.active]="activeLink === 'pricing'"
                        (click)="toggleClass(); onClick('pricing')">{{
                        'NAV_BAR.nav-item-link-5-ul-li-a' | translate }}</span></li>
                <li class="nav-item"><span class="nav-link" [class.active]="activeLink === 'blog'"
                        (click)="toggleClass(); onClick('blog')">{{
                        'NAV_BAR.nav-item-link-6-ul-li-a' | translate }}</span></li>
                <li class="nav-item"><span class="nav-link" [class.active]="activeLink === 'contact'"
                        (click)="toggleClass(); onClick('contact')">{{
                        'NAV_BAR.nav-item-link-7-ul-li-a' | translate }}</span></li>
            </ul>
            <ul class="navbar-nav  ml-auto flag-m">
                <li class="nav-item" ngbDropdown>
                    <div class="d-flex">
                        <img ngbDropdownToggle [src]="this.selectedLanguage.flagUrl" alt="language flag" width="24"
                            height="20" class="nav-link right" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false" id="button-basic" aria-controls="dropdown-basic" />
                        <!-- <span [class.text-white]="!isSticky" [class.text-black]="isSticky">{{ selectedLanguage.code}}</span> -->
                    </div>
                    <ul ngbDropdownMenu class="dropdown-menu-left gap-4 flex-column p-2 navbar-dropdown">
                        <li *ngFor="let lang of languages" class="cursor-pointer" role="menuitem"
                            (click)="switchLang(lang)">
                            <div class="d-flex align-items-center gap-2 " ngbDropdownItem>
                                <img width="24" height="20" class="flag mr-8" [src]="lang.flagUrl"
                                    alt="{{ lang.name }}">
                                <span> {{lang.title}}</span>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
            <!-- <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ selectedLanguage ? selectedLanguage.code : 'Select Lang' }}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" *ngFor="let lang of languages" (click)="switchLang(lang)">
                    <img [src]="lang.flagUrl" alt="{{ lang.name }}" class="flag-icon" />
                    {{lang.code}}
                  </a>
                </div>
            </div> -->
            <!-- <div class="navbar-nav ml-auto">
                <ul class="selectpicker nav-item dropdown">
                    <li class="nav-link dropdown-toggle" id="dropdown09" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img class="flag mr-8" alt="language flag" [src]="'assets/img/flags/'+ this.selectedLanguage.flag+'.png'">
                        <span> {{this.selectedLanguage.code}}</span>
                    </li>
                    <div class="dropdown-menu">
                        <li class="dropdown-item" *ngFor="let lang of languages" (click)="switchLang(lang)">
                            <img class="flag mr-8" alt="language flag" [src]="'assets/img/flags/'+ lang.flag+'.png'">
                            <span> {{lang.code}}</span>
                        </li>
                    </div>
                </ul>
            </div> -->
            <!-- <div class="header__language">
                <button type="button" mat-button [matMenuTriggerFor]="menu">
                  <div class="flag-circle" *ngIf="selectedLanguage && selectedLanguage?.flag">
                    <img alt="language flag" [src]="'assets/img/flags/' + selectedLanguage.flag" alt="flag" />
                  </div>
                  <span>{{ selectedLanguage?.title }}</span>
                </button>
                <mat-menu #menu="matMenu">
                  <button type="button" mat-menu-item *ngFor="let language of languages" [value]="language?.id"
                    (click)="switchLang(language.id)">
                    {{ language?.title }}
                  </button>
                </mat-menu>
              </div> -->
        </div>
    </div>
</nav>