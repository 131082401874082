<div class="home-slides">
    <owl-carousel-o [options]="homeSlides">
        <ng-template carouselSlide>
            <div class="main-banner item-bg-two">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">
                                <h2>{{ 'SECTION-HOME.main-banner-item-bg-one-text-h4' | translate }}</h2>
                                <h1>{{ 'SECTION-HOME.main-banner-item-bg-one-text-h1' | translate }}</h1>
                                <p>{{ 'SECTION-HOME.main-banner-item-bg-one-text-p' | translate }}</p>
                                <a href="#" (click)="[onClick('about'),handleButtonClick($event)]"  class="btn btn-primary">{{ 'SECTION-HOME.main-banner-item-bg-one-text-services' | translate }}</a>
                                <a href="#" (click)="[onClick('work'),handleButtonClick($event)]"  class="btn btn-primary view-work">{{ 'SECTION-HOME.main-banner-item-bg-one-text-pricing' | translate }}</a>
                                <!-- <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg-one">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">
                                <h2>{{ 'SECTION-HOME.main-banner-item-bg-two-text-h4' | translate }}</h2>
                                <h1>{{ 'SECTION-HOME.main-banner-item-bg-two-text-h1' | translate }}</h1>
                                <p>{{ 'SECTION-HOME.main-banner-item-bg-two-text-p' | translate }}</p>
                                <a href="#" (click)="[onClick('about'),handleButtonClick($event)]"  class="btn btn-primary">{{ 'SECTION-HOME.main-banner-item-bg-two-text-about' | translate }}</a>
                                <a href="#" (click)="[onClick('work'),handleButtonClick($event)]"  class="btn btn-primary view-work">{{ 'SECTION-HOME.main-banner-item-bg-two-text-work' | translate }}</a>
                                <!-- <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg-three">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-text">
                                <h2>{{ 'SECTION-HOME.main-banner-item-bg-three-text-h4' | translate }}</h2>
                                <h1>{{ 'SECTION-HOME.main-banner-item-bg-three-text-h1' | translate }}</h1>
                                <p>{{ 'SECTION-HOME.main-banner-item-bg-three-text-p' | translate }}</p>
                                <!-- <a href="#about" class="btn btn-primary">Get Started</a> -->
                                <!-- <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>

<app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<!-- <app-skill></app-skill> -->

<!-- <app-funfacts></app-funfacts> -->

<!-- <app-team></app-team> -->

<!-- <app-how-we-work></app-how-we-work> -->

<app-services-two></app-services-two>

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<!-- <app-feedback></app-feedback> -->

<app-blog></app-blog>

<!-- <app-partner></app-partner> -->

<!-- <app-subscribe></app-subscribe> -->

<app-contact></app-contact>
