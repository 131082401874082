import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { SharedService } from 'src/app/utility/services/shared.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  logoPath;
  constructor(private viewportScroller: ViewportScroller,
    private sharedService: SharedService,
  ) {
    this.sharedService.getLanguageChange().subscribe(res => {
      this.logoPath = `assets/img/site/Maksellent_Logo_${res}_white.svg`;
    })
  }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
  ngOnInit() {
  }
}
