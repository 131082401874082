import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import {
    CheckEmptyPipe,
    FormatDecimalPipe,
    FileSizePipe,
    PlaceNAPipe,
    TruncatePipe,
} from "./pipes";
import { MaterialModule } from "./material";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        TranslateModule,
        ReactiveFormsModule,
        HttpClientModule,
    ],
    declarations: [
        TruncatePipe,
        CheckEmptyPipe,
        PlaceNAPipe,
        FormatDecimalPipe,
        FileSizePipe,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MaterialModule,
        TruncatePipe,
        CheckEmptyPipe,
        PlaceNAPipe,
        FormatDecimalPipe,
        FileSizePipe,
    ],
    providers: [
    ],
})
export class UtilityModule {}
