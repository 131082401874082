<div class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h3>{{ 'SECTION-ESERVICES.fifth-section-heading-h4' | translate }}</h3>
                <h2>{{ 'SECTION-ESERVICES.fifth-section-heading-h2' | translate }}</h2>
                <p>{{ 'SECTION-ESERVICES.fifth-section-p-tag' | translate }}</p>
                <a href="#" (click)="[onClick('contact'),handleButtonClick($event)]" class="btn btn-primary">{{ 'SECTION-ESERVICES.fifth-section-button-one' | translate }}</a>
                <a href="#"  (click)="[onClick('work'),handleButtonClick($event)]" class="btn btn-primary view-work">{{ 'SECTION-ESERVICES.fifth-section-button-two' | translate }}</a>
            </div>
        </div>
    </div>
</div>
