<div class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 [innerHTML]="'SECTION-FAQ.faq-title-section-heading-h2' | translate "></h2>
            <p>{{ 'SECTION-FAQ.faq-title-section-p' | translate }}</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <div class="accordion">
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(0)" [ngClass]="{ 'open': isSectionOpen(0) }">
                                {{ 'SECTION-FAQ.faq-accordion-heading-1' | translate }}
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(0) }">
                                <p>{{ 'SECTION-FAQ.faq-accordion-body-1' | translate }}</p>
                                <p>{{ 'SECTION-FAQ.faq-accordion-body-1-one' | translate }}</p>
                                <p>{{ 'SECTION-FAQ.faq-accordion-body-1-two' | translate }}</p>
                                <p>{{ 'SECTION-FAQ.faq-accordion-body-1-three' | translate }}</p>
                                <p>{{ 'SECTION-FAQ.faq-accordion-body-1-four' | translate }}</p>
                                <p>{{ 'SECTION-FAQ.faq-accordion-body-1-five' | translate }}</p>
                                <p>{{ 'SECTION-FAQ.faq-accordion-body-1-six' | translate }}</p>
                                <p>{{ 'SECTION-FAQ.faq-accordion-body-1-seven' | translate }}</p>
                                <p>{{ 'SECTION-FAQ.faq-accordion-body-1-eight' | translate }}</p>
                                <p>{{ 'SECTION-FAQ.faq-accordion-body-1-nine' | translate }}</p>
                                <p>{{ 'SECTION-FAQ.faq-accordion-body-1-ten' | translate }}</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(1)" [ngClass]="{ 'open': isSectionOpen(1) }">
                                What are the different types of undergraduate degrees?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(1) }">
                                <p>Associate: a two-year program that either leads to a specific vocation or transitions to a bachelor program. Bachelor: a four or five-year program where students earn credits in a wide variety of courses.</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(2)" [ngClass]="{ 'open': isSectionOpen(2) }">
                                What are the different types of graduate degrees?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(2) }">
                                <p>Masters: two-year degree providing additional specialization. Doctorate: five to eight-year program certifying the student as a trained research scholar and/or professor.</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(3)" [ngClass]="{ 'open': isSectionOpen(3) }">
                                Can you work while studying in the United States?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(3) }">
                                <p>With permission of the International Student Office, international students may work on campus up to 20 hours/week their first year and can apply to work off-campus in subsequent years.</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(4)" [ngClass]="{ 'open': isSectionOpen(4) }">
                                What is distance education?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(4) }">
                                <p>Distance education occurs when a student and an instructor are in different places. Learning occurs by mail, telephone, internet, or by other means.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq.svg" width="100%" height="100%" alt="faq image">
                </div>
            </div>
        </div>
    </div>
</div>
