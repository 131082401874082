import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NoteService {

    constructor(private http: HttpClient) { }

    getNotesData(type: string): Observable<any> {
        const obj = {
            "pageNumber": 0,
            "pageSize": 5,
            "type": type,
            "status": 1,
            "orderBy": "sequence",
            "orderByDirection": "a2z"
        }
        return this.http.post<any>('https://stage.maksellent.com/api/v1/Note/Note/GetPublicNotesByFiltersAsync', obj);
    }
    getSystemSettingBytype(type: string): Observable<any> {

        return this.http.get<any>(`https://stage.maksellent.com/api/v1/Note/SystemSetting/GetPublicSystemSettingsByTypeAsync?type=${type}`);
    }
}
