<div class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2 [innerHTML]="'SECTION-WHYWEDIFFERNT.eleven-section-heading-h2' | translate"></h2>
            <p>{{ 'SECTION-WHYWEDIFFERNT.eleven-section-p-tag' | translate }}</p>
        </div>
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="asset-management" checked>
            <label for="tab1">{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-one' | translate }}</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="management-and-audit">
            <label for="tab2">{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-two' | translate }}</label>
            <!-- Tab 3 -->
            <!-- <input type="radio" name="tabset" id="tab3" aria-controls="RFID-solutions"> -->
            <!-- <label for="tab3">{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-three' | translate }}</label> -->
            <!-- Tab 4 -->
            <input type="radio" name="tabset" id="tab4" aria-controls="customer-management">
            <label for="tab4" class="mt-1">{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-five' | translate }}</label>

            <input type="radio" name="tabset" id="tab5" aria-controls="field-solutions">
            <label for="tab5" class="mt-1">{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-four' | translate }}</label>

            <div class="tab-panels">
                <div id="asset-management" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img2.svg" width="100%" height="100%" alt="img" loading="lazy">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-one-detail-h3' | translate }}</h3>
                                <p>{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-one-detail-p' | translate }}</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-one-detail-ul-li-1' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-one-detail-ul-li-2' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-one-detail-ul-li-3' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-one-detail-ul-li-4' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-one-detail-ul-li-5' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-one-detail-ul-li-6' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-one-detail-ul-li-7' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-one-detail-ul-li-8' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-one-detail-ul-li-9' | translate }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="management-and-audit" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img1.svg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-two-detail-h3' | translate }}</h3>
                                <p>{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-two-detail-p' | translate }}</p>
                                <ul>
                                  <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-two-detail-ul-li-1' | translate }}</li>
                                   <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-two-detail-ul-li-2' | translate }}</li>
                                  <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-two-detail-ul-li-3' | translate }}</li>
                                  <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-two-detail-ul-li-4' | translate }}</li>
                                  <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-two-detail-ul-li-5' | translate }}</li>
                                   <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-two-detail-ul-li-6' | translate }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="RFID-solutions" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img3.svg" alt="img" loading="lazy">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-three-detail-h3' | translate }}</h3>
                                <p>{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-three-detail-p' | translate }}</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-three-detail-ul-li-1' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-three-detail-ul-li-2' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-three-detail-ul-li-3' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-three-detail-ul-li-4' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-three-detail-ul-li-5' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-three-detail-ul-li-6' | translate }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="customer-management" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img4.svg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-four-detail-h3' | translate }}</h3>
                                <p>{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-four-detail-p' | translate }}</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-four-detail-ul-li-1' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-four-detail-ul-li-2' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-four-detail-ul-li-3' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-four-detail-ul-li-4' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-four-detail-ul-li-5' | translate }}</li>
                                    <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-four-detail-ul-li-6' | translate }}</li>
                                </ul>
                            </div>
                        </div>
                <div id="field-solutions" class="tab-panel">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-12">
                                <div class="why-we-different-img">
                                    <img src="assets/img/different-img4.svg" alt="img" loading="lazy">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="why-we-different-content">
                                    <h3>{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-five-detail-h3' | translate }}</h3>
                                    <p>{{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-five-detail-p' | translate }}</p>
                                    <ul>
                                        <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-five-detail-ul-li-1' | translate }}</li>
                                        <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-five-detail-ul-li-2' | translate }}</li>
                                        <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-five-detail-ul-li-3' | translate }}</li>
                                        <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-five-detail-ul-li-4' | translate }}</li>
                                        <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-five-detail-ul-li-5' | translate }}</li>
                                        <li><i class="fas fa-check"></i> {{ 'SECTION-WHYWEDIFFERNT.eleven-section-button-five-detail-ul-li-6' | translate }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
