<div id="work" class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <!-- <h2>Our <span>Work</span></h2> -->
            <h2 [innerHTML]="'SECTION-WORK.work-section-heading-h2' | translate"></h2>
            <p>{{ 'SECTION-WORK.work-section-p-tag' | translate }}</p>
        </div>
    </div>
    <div class="container-fluid">
        <div class="work-tabs">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        {{ 'SECTION-WORK.work-section-ul-li-1' | translate }}
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">
                        {{ 'SECTION-WORK.work-section-ul-li-2' | translate }}
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                    <span (click)="switchTab($event, 'tab3')">
                        {{ 'SECTION-WORK.work-section-ul-li-3' | translate }}
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                    <span (click)="switchTab($event, 'tab4')">
                        {{ 'SECTION-WORK.work-section-ul-li-4' | translate }}
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}">
                    <span (click)="switchTab($event, 'tab5')">
                        {{ 'SECTION-WORK.work-section-ul-li-5' | translate }}
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab6'}">
                    <span (click)="switchTab($event, 'tab6')">
                        {{ 'SECTION-WORK.work-section-ul-li-6' | translate }}
                    </span>
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab11" *ngIf="currentTab === 'tab1'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>{{ 'SECTION-WORK.work-section-ul-li-2' | translate }}</h3>
                                    <ul>
                                        <li><a (click)="open('https://app.maksellent.com','8001')">{{ 'BUTTON.GO-TO-APP' | translate }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>{{ 'SECTION-WORK.work-section-ul-li-3' | translate }}</h3>
                                    <ul>
                                        <li><a (click)="open('https://app.maksellent.com','8001')">{{ 'BUTTON.GO-TO-APP' | translate }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img3.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>{{ 'SECTION-WORK.work-section-ul-li-4' | translate }}</h3>
                                    <ul>
                                        <li><a (click)="open('https://app.maksellent.com','8001')">{{ 'BUTTON.GO-TO-APP' | translate }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img4.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>{{ 'SECTION-WORK.work-section-ul-li-5' | translate }}</h3>
                                    <ul>
                                        <li><a (click)="open('https://app.maksellent.com','8001')">{{ 'BUTTON.GO-TO-APP' | translate }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img5.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>{{ 'SECTION-WORK.work-section-ul-li-6' | translate }}</h3>
                                    <ul>
                                        <li><a (click)="open('https://app.maksellent.com','8001')">{{ 'BUTTON.GO-TO-APP' | translate }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img6.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>{{ 'SECTION-WORK.work-section-ul-li-7' | translate }}</h3>
                                    <ul>
                                        <li><a (click)="open('https://app.maksellent.com','8001')">{{ 'BUTTON.GO-TO-APP' | translate }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab12" *ngIf="currentTab === 'tab2'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>{{ 'SECTION-WORK.work-section-ul-li-8' | translate }}</h3>
                                    <ul>
                                        <li><a (click)="open('https://app.maksellent.com','8001')">{{ 'BUTTON.GO-TO-APP' | translate }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img6.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>{{ 'SECTION-WORK.work-section-ul-li-9' | translate }}</h3>
                                    <ul>
                                        <li><a (click)="open('https://app.maksellent.com','8001')">{{ 'BUTTON.GO-TO-APP' | translate }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab13" *ngIf="currentTab === 'tab3'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>Games Design</h3>
                                    <ul>
                                        <li><a href="#">IT</a></li>
                                        <li> . </li>
                                        <li><a href="#">Games</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab14" *ngIf="currentTab === 'tab4'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img2.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>Games Design</h3>
                                    <ul>
                                        <li><a href="#">IT</a></li>
                                        <li> . </li>
                                        <li><a href="#">Games</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img3.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab15" *ngIf="currentTab === 'tab5'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img3.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img4.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab16" *ngIf="currentTab === 'tab6'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img4.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img5.jpg" alt="work-img" width="100%" height="100%">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
