import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SharedService } from 'src/app/utility/services/shared.service';
// import * as _ from 'lodash';

interface Language {
    id: string;
    title: string;
    code: string;
    flag: string;
    flagUrl: string;
}

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    @ViewChild("selectpicker") selectPicker: ElementRef;

    languages: Language[] = [
        {
            id: "en",
            title: "English",
            code: "EN",
            flag: "us",
            flagUrl: "assets/img/flags/us.svg",
        },
        {
            id: "tr",
            title: "Türkçe",
            code: "TR",
            flag: "tr",
            flagUrl: "assets/img/flags/tr.svg",
        },
        {
            id: "de",
            title: "Deutsch",
            code: "DE",
            flag: "de",
            flagUrl: "assets/img/flags/de.svg",
        }
    ];
    selectedLanguage: Language | null = null;
    logoPath;
    activeLink: string = 'home'; // Default active link

    constructor(
        private viewportScroller: ViewportScroller,
        private titleService: Title,
        private translate: TranslateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public sharedService: SharedService,
    ) {

        translate.addLangs(["en", "de", "tr"]);
        translate.setDefaultLang("en");
        // Attemp to remove Loadash
        // this.selectedLanguage = _.find(this.languages, {
        //     id: this.translate.getBrowserLang(),
        // });

        // Set the selected language from default languages
        this.selectedLanguage = this.languages.find((obj: any) => {
            return obj.id === this.translate.getBrowserLang();
        });

        let browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|fr/) ? browserLang : "en");
        this.logoPath = `assets/img/site/Maksellent_Logo_${this.selectedLanguage.id}_white.svg`;
        const languageType = browserLang.match(/en|fr|tr/) ? browserLang : "en";
        const subscription = this.activatedRoute.params.subscribe((params: Params) => {
            if (!params['id']) {
                // this.router.navigate(['/lang/' + languageType]);
            }
        });
        this.sharedService.setLanguageChange(languageType);
    }

    // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
            this.logoPath = `assets/img/site/Maksellent_Logo_${this.selectedLanguage.id}_black.svg`;
        } else {
            this.isSticky = false;
            this.logoPath = `assets/img/site/Maksellent_Logo_${this.selectedLanguage.id}_white.svg`;

        }
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
        this.activeLink = elementId;

    }

    ngOnInit() {
        // $(".selectpicker").selectpicker();
        this.getTitle();

    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    switchLang(lang: any) {
        this.selectedLanguage = lang;
        this.translate.use(this.selectedLanguage.id);
        this.getTitle();
        const subscription = this.activatedRoute.params.subscribe((params: Params) => {
            if (!params['id']) {
                this.router.navigate(['/lang/' + this.selectedLanguage.id]);
            }
        });
        this.sharedService.setLanguageChange(this.selectedLanguage.id);
    }
    getTitle() {
        this.translate.get("INDEX_FILE.index-title").subscribe((data) => {
            //  console.log(data);
            this.titleService.setTitle(data);
        });
        this.logoPath = `assets/img/site/Maksellent_Logo_${this.selectedLanguage.id}_white.svg`;

    }
}
