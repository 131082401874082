<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h3>We are creative</h3>
            <h2>Welcome to <span>Maksellent</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Creative Design</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectet adipiscing elit consectetur, ipsum dolor sed do eiusmod tempor.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <h3>Friendly Codes</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectet adipiscing elit consectetur, ipsum dolor sed do eiusmod tempor.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-life-ring"></i>
                    </div>
                    <h3>Fast Support</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectet adipiscing elit consectetur, ipsum dolor sed do eiusmod tempor.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" width="100%" height="100%" alt="image"></div>
</div>
