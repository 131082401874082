<div id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h3>{{ 'SECTION-BLOG.blog-title-section-heading-h4' | translate }}</h3>
            <h2 [innerHTML]="'SECTION-BLOG.blog-title-section-heading-h2' | translate "></h2>
            <p>{{ 'SECTION-BLOG.blog-title-section-p' | translate }}</p>
        </div>
        <div class="blog-slides">
            <owl-carousel-o [options]="blogSlides">
                <ng-template carouselSlide>
                    <div class="single-blog-item bg1">
                        <span>{{ 'SECTION-BLOG.blog-single-blog-item-bg1-span' | translate }}</span>
                        <h3><a routerLink="/blog-details">{{ 'SECTION-BLOG.blog-single-blog-item-bg1-h3' | translate }}</a></h3>
                        <p> {{ 'SECTION-BLOG.blog-single-blog-item-bg1-p' | translate }}</p>
                        <a routerLink="/blog-details" [title]="'SECTION-BLOG.blog-single-blog-item-bg1-a-title' | translate" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg2">
                        <span>{{ 'SECTION-BLOG.blog-single-blog-item-bg2-span' | translate }}</span>
                        <h3><a routerLink="">{{ 'SECTION-BLOG.blog-single-blog-item-bg2-h3' | translate }}</a></h3>
                        <p> {{ 'SECTION-BLOG.blog-single-blog-item-bg2-p' | translate }}</p>

                        <a routerLink="" [title]="'SECTION-BLOG.blog-single-blog-item-bg2-a-title' | translate" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg3">
                        <span>{{ 'SECTION-BLOG.blog-single-blog-item-bg3-span' | translate }}</span>
                        <h3><a routerLink="">{{ 'SECTION-BLOG.blog-single-blog-item-bg3-h3' | translate }}</a></h3>
                        <p> {{ 'SECTION-BLOG.blog-single-blog-item-bg3-p' | translate }}</p>

                        <a routerLink="" [title]="'SECTION-BLOG.blog-single-blog-item-bg3-a-title' | translate" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg4">
                        <span>{{ 'SECTION-BLOG.blog-single-blog-item-bg4-span' | translate }}</span>
                        <h3><a routerLink="">{{ 'SECTION-BLOG.blog-single-blog-item-bg4-h3' | translate }}</a></h3>
                        <p> {{ 'SECTION-BLOG.blog-single-blog-item-bg4-p' | translate }}</p>

                        <a routerLink="" [title]="'SECTION-BLOG.blog-single-blog-item-bg4-a-title' | translate" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>{{ 'SECTION-BLOG.blog-single-blog-item-bg5-span' | translate }}</span>
                        <h3><a routerLink="/blog-details">{{ 'SECTION-BLOG.blog-single-blog-item-bg5-h3' | translate }}</a></h3>
                        <p> {{ 'SECTION-BLOG.blog-single-blog-item-bg5-p' | translate }}</p>

                        <a routerLink="" [title]="'SECTION-BLOG.blog-single-blog-item-bg5-a-title' | translate" class="link-btn"><i class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
